import ContentEditable from '../../common/forms/ContentEditable.vue'
import Select from '../../common/forms/Select.vue'
import Input from '../../common/forms/Input.vue'
import BtnEditionSuppressionMini from '../../common/ui/btn_edition_suppression_mini.vue'
import TipTap from '../../common/forms/TipTap.vue' 
import cloneDeep from 'lodash/cloneDeep'

export default {
	data() {
    return {
      date: '',
      note: '',
      type: '',
      errors: {}
    }
  },
  props: {
    element: {
      type: Object,
      required: false,
      default: () => ({})
    },
    types: {
      type: Array,
      required: true,
      default: () => []
    },
    edition: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch:{
    edition: function(after, before){
      if(after){
        this.date = this.element.date.split('T')[0] ?? new Date().toISOString().split('T')[0]
        this.note = this.element.note ?? ''
        this.type = this.element.type ?? ''
      
        //AS--> Remplace les \n par des <br />
        this.note = this.note.replace(/\n/g, '<br />')
        
      }
    }
  },
  created(){
    if(this.edition){
      this.date = this.element.date?.split('T')[0] ?? new Date().toISOString().split('T')[0]
    }
  },
  methods:{
    editionSave(){

      this.errors = {}

      //AS--> Vérification des champs obligatoires
      if(!this.type){
        let errors = cloneDeep(this.errors)
        errors['type'] = [this.$t('suivi_activites_type_required')]
        this.errors = errors
      }

      //AS--> Si des erreurs sont présentes, on ne continue pas
      if(Object.keys(this.errors).length > 0) return

      //AS--> On créer l'élément à sauvegarder
      let element = {
        date: this.date+'T00:00:00.000Z',
        note: this.note,
        type: this.type
      }

      //AS--> On émet l'événement de sauvegarde
      this.$emit('update', element)
    },
    editionOff(){
      this.$emit('stop', this.element)
    },
    editionOn(){
      this.$emit('start', this.element._id)
    },
    suppression(){
      this.$emit('delete', this.element._id)
    }
  },
  computed:{
    
  },
  components:{
    ContentEditable,
    Select,
    Input,
    BtnEditionSuppressionMini,
    TipTap
  }
}