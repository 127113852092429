export default {
    name: 'BtnEditionSuppressionMini',
    data() {
        return {
        }
    },
    props: {
        edition: {
            type: Boolean,
            default: true
        },
        editionConfirm: {
            type: String,
            default: null
        },
        suppression: {
            type: Boolean,
            default: true
        },
        suppressionConfirm: {
            type: String,
            default: null
        }
    },
    methods: {
        actionEdition() {
            let self = this
            if(this.editionConfirm){
                this.$dialog.confirm(this.editionConfirm, {loader: true})
                .then(dialog => {
                    self.$emit('edition')
                    dialog.close();
                })
            }else{
                self.$emit('edition')
            }
        },
        actionSuppression() {
            let self = this
            if(this.suppressionConfirm){
                this.$dialog.confirm(this.suppressionConfirm, {loader: true})
                .then(dialog => {
                    self.$emit('suppression')
                    dialog.close();
                })
            }else{
                self.$emit('suppression')
            }
        }
    }
}