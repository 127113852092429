<template>
    <div class="tiptap">
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
            <div class="menubar">

            <button class="menubar__button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold" >
                <img v-svg-inline src="../../assets/tiptap/bold.svg" />
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic" >
                <img v-svg-inline src="../../assets/tiptap/italic.svg" />
            </button>

            <button v-if="btnParagraph" class="menubar__button" :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph" >
                <img v-svg-inline src="../../assets/tiptap/paragraph.svg" />
            </button>

            <button v-if="btnTitre1" class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 4 }) }" @click="commands.heading({ level: 4 })" >
                {{ $t('tiptap_titre') }} 1
            </button>

            <button v-if="btnTitre2" class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 5 }) }" @click="commands.heading({ level: 5 })" >
                {{ $t('tiptap_titre') }} 2
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list" >
                <img v-svg-inline src="../../assets/tiptap/ul.svg" />
            </button>

            <!-- button class="menubar__button" :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list" >
                <img v-svg-inline src="../../assets/tiptap/ol.svg" />
            </button -->

            <button class="menubar__button" @click="commands.undo" >
                <img v-svg-inline src="../../assets/tiptap/undo.svg" />
            </button>

            <button class="menubar__button" @click="commands.redo" >
                <img v-svg-inline src="../../assets/tiptap/redo.svg" />
            </button>

            </div>
        </editor-menu-bar>
        <editor-content :editor="editor" :class="classStyles" />
    </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { 
	HardBreak,
	Heading,
	BulletList,
	ListItem,
	Bold,
	Italic,
	History,
} from 'tiptap-extensions'

export default {
    name: 'TipTap',
    data() {
        return {
            editor: new Editor({
                content: this.value,
                extensions: [
					new BulletList(),
					new HardBreak(),
					new Heading({levels: [4,5]}),
					new ListItem(),
					new Bold(),
					new Italic(),
					new History(),
                ],
                onUpdate: () => {
                    this.$emit('input', this.editor.getHTML())
                }
            })
        }
    },
    props: {
        value: {
            type: String,
            default: ''
        },
		classStyles: {
			type: String,
			default: ''
		},
        btnParagraph: {
            type: Boolean,
            default: false
        },
        btnTitre1: {
            type: Boolean,
            default: true
        },
        btnTitre2: {
            type: Boolean,
            default: true
        }
    },
    mounted() {
        this.editor.setOptions({
            extensions: [
                new Bold(),
                new Italic(),
                new History(),
            ]
        })
    },
    components: {
        EditorContent,
        EditorMenuBar
    }
}
</script>