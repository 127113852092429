import _ from 'lodash'

export default {
	props: ['params'],
	methods: {
		removeValue(tag){
			let self = this
			let carac_id = parseInt(self.params.field.replace('carac.', ''))

			//AS--> Prend la valeur de la caractéristique et trouve le code relatif dans la nomenclature
			tag = this.findNomenclatureKeyFromValueInLang(carac_id, tag, self.$store.getters.langCode)

			//AS--> Récupère la liste de toutes les caracteristiques actuellement en place sur la matière
			let matiere_caracteristics = this.findAllExistingValuesForCaracteristic(carac_id)
			//AS--> Retire tag de la liste des caractéristiques
			matiere_caracteristics = _.without(matiere_caracteristics, tag)

			//AS--> Remplace les valeurs de la caracteristique
			this.replaceCaracteristicValuesInLangues(carac_id, matiere_caracteristics)
		},
		replaceCaracteristicValuesInLangues(carac_id, values){
			let self = this
			let matiere = this.$store.state.company.edition_matiere;

			//AS--> Transforme carac_id en int
			carac_id = parseInt(carac_id)
			let carac_list = self.$store.getters.getNomenclatureList_ByType_allLanguages('caracteristics', carac_id)

			//AS--> Si la nomenclature existe
			if(carac_list){

				//AS--> Retire des caracteristiques toutes les valeurs liées à la caractéristique carac_id
				matiere.characteristics = _.filter(matiere.characteristics, function(carac){
					return carac.forme_id != carac_id
				})

				//AS--> Ajoute les nouvelles valeurs à la caractéristique carac_id
				values.forEach(function(value){
					
					if(carac_list[value] && carac_list[value].name){
						//AS--> Pour chaque langue présente dans la nomenclature, on ajoute la valeur
						_.forEach(carac_list[value].name, function(carac_langue, langue_name){
							let langue_id = self.$store.state.ui.langues_ids[langue_name]
							matiere.characteristics.push({forme_id: carac_id, value: carac_langue, langue_id})
						})
					}
				})
			
			//AS--> Si la nomenclature n'existe pas, on ne supprime que la valeur dans la langue en cours et on la recrée avec la valeur brute reçue
			}else{
				matiere.characteristics = _.filter(matiere.characteristics, function(carac){
					return carac.forme_id != carac_id || carac.langue_id != self.$store.getters.langId
				})
				matiere.characteristics.push({forme_id: carac_id, value: values[0], langue_id: self.$store.getters.langId})
			}
		},
		findAllExistingValuesForCaracteristic(carac_id){
			let self = this
			let matiere = this.$store.state.company.edition_matiere;

			//AS--> Transforme carac_id en int
			carac_id = parseInt(carac_id)

			//AS--> On boucle sur les valeurs actuelles de la caractéristique
			let matiere_caracteristic_values = []
			
			matiere.characteristics.forEach(function(carac){

				//AS--> Si la valeur de la caractéristique correspond à l'id de la caractéristique qu'on souhaite traiter
				if(carac.forme_id == carac_id){

					let langue_name = _.invert(self.$store.state.ui.langues_ids)[carac.langue_id]

					let value_key = self.findNomenclatureKeyFromValueInLang(carac_id, carac.value, langue_name)
					if(value_key) matiere_caracteristic_values.push(value_key)

				}
			})

			return _.uniq(matiere_caracteristic_values)
		},
		findNomenclatureKeyFromValueInLang(carac_id, value, lang_code){
			let self = this
			carac_id = parseInt(carac_id)

			//AS--> Récupère la nomenclature des valeurs de la caractéristique
			let carac_list = self.$store.getters.getNomenclatureList_ByType_allLanguages('caracteristics', carac_id)

			let value_key = null

			//AS--> On boucle sur la nomenclature pour trouver la clé qui correspond à la valeur de la caractéristique dans sa langue de saisie
			_.forEach(carac_list, function(carac_list_value, carac_list_value_key){
				if(carac_list_value.name[lang_code] == value){
					value_key = carac_list_value_key
				}
			})
			return value_key
		}
	},
	computed: {
		valeur: {
			get () {
				let self = this
				this.$store.state.company.edition_matiere_uniq; //AS--> Pour la réactivité avec les valeurs modifiées à la volée qui ne changent pas dans edition_matiere mais dont le changement doit s'opérere dans le champ
				let field = this.params.field.split('.');
				let val = self.isMultiple ? [] : null
				if(field.length == 2){
					// val = this.$store.state.company.edition_matiere[field[0]] ? this.$store.state.company.edition_matiere[field[0]][field[1]] : null;
					if(field[0] == 'carac' || field[0] == 'characteristics'){
						this.$store.state.company.edition_matiere.characteristics.forEach(function(carac){
							if(carac.forme_id == field[1] && carac.value != undefined && carac.langue_id == self.$store.getters.langId){
								if(!self.isMultiple){
									val = carac.value
								}else{
									val.push(carac.value)
								}
							}
						})
					}else{
						val = this.$store.state.company.edition_matiere[field[0]] ? this.$store.state.company.edition_matiere[field[0]][field[1]] : null;
					}
				}else{
					// val = this.$store.state.company.edition_matiere[field[0]];
					val = this.$store.state.company.edition_matiere[field[0]]
				}
				if(typeof val == 'undefined') val = null;
				return val;
			},
			set (value) {
				
				if(value === undefined) return
				
				//AS--> Vérifications
				if(!_.isNull(value) && this.params.type && this.params.type == 'number'){
					let regex = /[^0-9.,]/i;
					value = value.replace(regex, '');
				}
				
				let matiere = this.$store.state.company.edition_matiere;
				let field   = this.params.field.split('.');
				
				//AS--> Si le field est composé de 2 parties
				if(field.length == 2){
					
					//AS--> Si la première partie est l'entrée des caractéristiques
					if(field[0] == 'carac' || field[0] == 'characteristics'){
						
						//AS--> D'après la valeur reçue, on détermine le code de la nomenclature
						let nomenclature_key = this.findNomenclatureKeyFromValueInLang(field[1], value, this.$store.getters.langCode) ?? value
						
						if(this.isMultiple){
							//AS--> On récupère toutes les valeurs existantes pour la caractéristique et on ajoute la nouvelle valeur
							let values = this.findAllExistingValuesForCaracteristic(field[1])
							values.push(nomenclature_key)
							value = _.uniq(values)
						}else{
							//AS--> On transforme la valeur en tableau
							value = [nomenclature_key]
						}
						
						//AS--> On remplace les valeurs de la caractéristique par celles correspondnant à l'array de "value"
						this.replaceCaracteristicValuesInLangues(field[1], value)
						
					//AS--> Si ce n'est pas pour les caractéristiques
					}else{
						if(!matiere[field[0]]) matiere[field[0]] = {};
						matiere[field[0]][field[1]] = value;
					}
					
					
				}else{
					matiere[field[0]] = value;
				}
				
				this.$store.commit('UI_SET_COMPANY_EDITION_MATIERE', matiere);
			}
		},
		isMultiple(){
			let self = this;
			let multiple = false;
			if(this.params.field.startsWith('carac.')){
				_.forEach(this.$store.state.ui.dechets[0].modale, function(carac){
					if(carac.id == self.params.field.replace('carac.', '')) multiple = carac.multiple == 1
				})
			}
			return multiple
		}
	}
}