import axios from '../../common/axios.js'
import ContentEditable from '../../common/forms/ContentEditable.vue'
import FicheInfoSuiviActivitesBloc from './fiche_info_suivi_activites_bloc.vue'

export default {
	data() {
    return {
      loading: true,
      company: this.$store.state.company.company,
      edition: false,
      erreurs: {},
      editId: null,
      notes: []
    }
  },
  props: ['element'],
  watch:{
    "$store.state.company.company":function(after, before){
      this.company = after
      this.erreurs = {}
    }
  },
  created(){

    //AS--> Récupère les activités de l'entreprise au chargement de la fiche
    this.loadNotes()

  },
  methods:{

    loadNotes(){
      axios.get('api=inex/companies/'+this.company.id+'/activities').then(response => {
        let notes = response.data
        notes.sort((a, b) => new Date(b.date) - new Date(a.date))
        this.notes = notes
      }).finally(() => {
        this.loading = false
      })
    },

    editOn(id){
      this.editId = id
    },

    //AS--> Met à jour ou crée la note chez iNex
    update(element){
      if(this.editId){

        //AS--> Update chez iNex
        axios.put('api=inex/companies/'+this.company.id+'/activities/'+this.editId, element).then(response => {
          this.loadNotes()
        })

      }else{

        //AS--> Create chez iNex
        axios.post('api=inex/companies/'+this.company.id+'/activities', element).then(response => {
          this.loadNotes()
        })

      }
      this.editId = null
    },
    stop(element){
      console.log(element)
      this.editId = null
    },
    deleteNote(id){

      //AS--> Suppression de la note chez iNex
      axios.delete('api=inex/companies/'+this.company.id+'/activities/'+id).then(response => {
        this.loadNotes()
      })

    }
  },
  computed:{
    canEdit(){
      return true
    },
    getTypes(){
      let types = []
      this.element.types.forEach(type => {
        types.push({
          value: type,
          label: this.i18n(type)
        })
      })
      return types
    }
  },
  components:{
    ContentEditable,
    FicheInfoSuiviActivitesBloc
  }
}