<template>
  <span ref="champ" :contenteditable="edition" :class="getClasses" :placeholder="placeholder" @input="input" v-html="innerHTML"></span>
</template>

<script>
import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
export default {
  name: "ContentEditable",
  data() {
    return {
      innerHTML: this.data ?? this.value
    };
  },
  props: {
    value: String,
    data: [String, Number],
    edition: false,
    placeholder: String,
    focusOnEdition: false,
    spanClass: ''
  },
  watch:{
    edition: function(after, before){
      let self = this
      if(after == false) this.$refs.champ.textContent = this.innerHTML
      if(after == true && this.focusOnEdition){
        setTimeout(function(){
          self.$refs.champ.focus()
        }, 50)
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    _kebabCase(field) {
      return kebabCase(field);
    },
    input(event){
      this.$emit('input', event.target.innerText) // ? .textContent / .innerHTML / .innerText ?
    }
  },
  computed: {
    getClasses(){
      let classes = this.spanClass
      if(this.edition) classes += ' field_edition'
      return classes
    }
  },
  components: {},
};
</script>
<style scoped lang="scss">
span[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  color: gray;
  background-color: transparent;
  pointer-events: none;
}
</style>
